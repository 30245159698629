import React from 'react';

const About = () => {
  return (
    <div class="about">
        <p>About Us... </p>
    </div>
  );
};

export default About;

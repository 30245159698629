import React from 'react';

const Footer = () => {
  return (
    <nav className="footer">
      <p>Copyright 2024 | Vintage Drip</p>
    </nav>
  );
};

export default Footer;